<template>
<section id="receptions">
    <section id="advance-search">
        <div class="card mb-5 mb-xl-10">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse}`"
                aria-expanded="true"
                :aria-controls="idCollapse"
            >
                <div class="card-title m-0">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500 me-1">
                        <inline-svg src="media/icons/duotune/general/gen021.svg" />
                    </span>
                    <h3 class="fw-bolder ml-2">{{ $t('LABELS.ADVANCE_SERACH') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse">
                <div class="row">
                    <div class="col-4">
                        <label class="fs-6 fw-bold mb-2">Proveedor</label>
                        <el-select
                            :multiple="false"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="Proveedor"
                            :remote-method="(query) => searchSuppliers(query)"
                            v-model="filterSupplier"
                            clearable
                        >
                        <el-option
                            v-for="option in comboSupplier"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        />
                        </el-select>
                    </div>
                    <div class="col-4">
                        <label class="fs-6 fw-bold mb-2">Folio</label>
                        <el-input v-model="filterFolio" 
                        @input="onChangeFolio"
                        size="default"
                        :validate-event="false"
                        placeholder="Folio" />
                    </div>
                    <div class="col-4">
                        <label class="fs-6 fw-bold mb-2">Estatus</label>
                        <SelectOption style="width: 100%"
                            :options="comboStatus"
                            v-model="filterStatus"
                        />
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-3">
                        <label class="fs-6 fw-bold mb-2">Fecha de Inicio</label>
                        <el-date-picker 
                            v-model="filterStartDate"
                            class="w-100"
                            type="date"
                            format="DD/MM/YYYY"
                            placeholder="dd/MM/yyyy"
                        />
                    </div>
                    <div class="col-3">
                        <label class="fs-6 fw-bold mb-2">Fecha de Fin</label>
                        <el-date-picker 
                            v-model="filterEndDate"
                            class="w-100"
                            type="date"
                            format="DD/MM/YYYY"
                            placeholder="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button 
                        class="btn btn-sm btn-white me-3 float-start"
                        data-kt-menu-dismiss="true"
                        @click="clearFilters"
                    >
                        Limpiar Filtros
                    </button>
                    <button class="btn btn-success" @click="applyFilters">
                        <span class="svg-icon svg-icon-2 svg-icon-gray-500 me-1">
                            <inline-svg src="media/icons/duotune/general/gen021.svg" />
                        </span>
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section id="po-table">
    </section>
</section>
<ModuleMaster 
    :requireSearchField="true"
    :requireAddButton="true"
    :requirePaginator="true"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="5"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :fnCallbackSearchItems="searchItems"
>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="false" style="padding-top: 16px" :hover="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="false"
            :statusPropName="'status'"
        >
            <CrudActions 
                :itemID="row.ID" 
                :requireE="false" 
                :requireM="verifyCanEdit(row.ID)" 
                :callbackCrudAction="handleCrudAction"
                :iconSize="2"
            />
            <AditionalActions 
                :fnCallback="handleAditionalActionClick"
                :rowId="row.ID"
                :requireDetails="true"
                :requireDOC="false"
                :requireCI="canApplyStatus('CI', row.ID) && getCanApplyCI"
                :requireCF="canApplyStatus('CF', row.ID) && getCanApplyCF"
                :requireAWM="canApplyStatus('AA', row.ID) && getCanApplyAA"
                :requireDM="canApplyStatus('DM', row.ID)"
            />
            <div 
                class="btn btn-icon btn-sm btn-active-icon-primary"
                data-bs-toggle="tooltip" 
                data-bs-placement="bottom" 
                title="Abonos" 
                @click="handleAditionalActionClick('PP', row.ID)"
            >
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="media/icons/duotune/finance/fin008.svg" />
                </span>
            </div>
            <div v-if="getIsProcessingMovement(row.ID)" class="spinner-grow spinner-grow-sm text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalFormEditPO" ref="btnForm"></button>
<POEditForm ref="form" :mode="modeEditForm" :title="titleEditFormAction" :currentOpenedItem="currentPO" :fnCallBackPersistenceNotifier="handleItemSaved"/>
<button hidden data-bs-toggle="modal" data-bs-target="#modalPartialPayment" ref="btnFormPP"></button>
<PartialPayment ref="formPP" mode="N" title="Nuevo Abono" :purchaseOrderId="getPurchaseOrderIdPP" :redirectToDetails="true"/>
<button hidden ref="openPDF" data-bs-toggle="modal" data-bs-target="#viewPDF" />
<PDFViewer :base64="pdfMovement" :titulo="getPDFTitle" ></PDFViewer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import POEditForm from "./edit-form-purchase-order.vue";
import { useRouter } from "vue-router";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import AditionalActions from "./aditional-actions.vue";
import PartialPayment from "./partial-payment.vue";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue";
import { useStore } from "vuex";

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , CrudActions
        , AditionalActions
        , POEditForm
        , PartialPayment
        , SelectOption
        , PDFViewer
    },
    setup() {
        //VARIABLES Y CONSTANTES
        const controller = "purchaseOrders";
        const router = useRouter();
        const messageService = new SwalMessageService();
        let records = ref([]);
        let modeForm = ref("N");
        let titleEditForm = ref("Nuevo Artículo");
        let currentPage = ref(1);
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const btnFormPP = ref(null);
        const currentItem = ref(null);
        let textSearch = ref("");
        let textAdvancedSearch = ref("");
        const openPDF = ref(null);
        let pdfMovement = ref("");
        let form = ref(null);
        let formPP = ref(null);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar por Folio",
            titleNewCrudAction: "Nueva Orden de Compra",
        });
        let pdfTitle = ref("");
        let purchaseOrderIdProcessing = ref(null);
        let purchaseOrderIdPP = ref(null);
        let idCollapse = ref("filter-controls");
        let filterSupplier = ref("");
        let filterFolio = ref("");
        let filterStatus = ref("");
        let filterStartDate = ref("");
        let filterEndDate = ref("");
        let suppliers = ref([]);
        let comboStatus = [
            { id: "0", name: "Captura Inicial"},
            { id: "1", name: "Captura Final"},
            { id: "2", name: "Aprobado"},
            { id: "3", name: "Cancelado"}
        ]

        const arrayTitleActions = [
            { action: "N", title: "Nueva Orden de Compra" },
            { action: "M", title: "Modificar Orden de Compra" },
            { action: "V", title: "Visualizar Orden de Compra" }
        ];

        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "purchaseOrderSupplierFolio", HeadLabel: "Folio Proveedor", Type: "text", VisibleInGrid: true },
            { PropName: "supplierName", HeadLabel: "Proveedor", Type: "text", VisibleInGrid: true },
            { PropName: "freightNumber", HeadLabel: "Número de Carga", Type: "text", VisibleInGrid: true, Center: true },
            { PropName: "creditDays", HeadLabel: "Días de Crédito", Type: "text", VisibleInGrid: true, Center: true },
            { PropName: "tax", HeadLabel: "Impuesto", Type: "moneda", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
            { PropName: "statusName", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },
            { PropName: "statusReceptionName", HeadLabel: "Estatus Recepción", Type: "text", VisibleInGrid: true }
        ]);

        const store = useStore();

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Órdenes de Compra", ["Módulos"]);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "status");
        });

        const currentPO = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const getPurchaseOrderIdPP = computed(() => purchaseOrderIdPP.value);

        const comboSupplier = computed(() => suppliers.value);

        const getPDFTitle = computed(() => pdfTitle.value);

        /**PERMISOS */
        const getCanApplyCI = computed(() => store.getters["canApplyCI"]);
        const getCanApplyCF = computed(() => store.getters["canApplyCF"]);
        const getCanApplyAA = computed(() => store.getters["canApplyAA"]);

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            if(["N", "V", "M"].some(a => a === action)) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
                if(action === "M" || action === "V") {
                    await getArticleById(itemID);
                }
                if(btnForm.value) {
                    form.value.initDefaults();
                    btnForm.value.click();
                }
            } else if(action === "E") {
                let item = records.value.find(i => i.id === itemID);
                if(item) {
                    Swal.fire({
                        title: `Está a punto de ${ item.status ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                changeStatusItem(item);
                            }
                        }
                    );
                }
            } 
        }

        const changeStatusItem = async(item) => {
            let activate = !item.status;
            let url = `${controller}/${item.id}/${activate ? 'activate' : 'deactivate'}`;
            await ApiService.put(url).then(res => {
                item.status = activate;
                handleItemSaved(item);
                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }

        const getPagedItems = async() => {
            let criteriaSearch = `Criteria.Active=true${textSearch.value.trim() != "" ? `&Criteria.Folio=${textSearch.value.trim()}` : ""}`;
            let url = `${controller}/GetPagedList?currentPage=${currentPage.value}&PageSize=${cantRecordsPage}&${criteriaSearch}${textAdvancedSearch.value}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getArticleById = async(id) => {
            ApiService.get(`${controller}/${id}`).then(res => {
                currentItem.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "N":
                    if(currentPage.value > 1) {
                        goToPage(1);
                    } else {
                        ApiService.get(`${controller}/${item.id}`).then(res => {
                            if(records.value.length == cantRecordsPage){
                                records.value = [res.data, ...records.value.slice(0, cantRecordsPage - 1)]
                            } else {
                                records.value = [res.data, ...records.value];
                            }
                            totalItems.value += 1;  
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        });                  
                    }
                    goToDetails(item.id);
                    break;
                case "M":
                    updateRowItem(item);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = (item) => {
            let itemRow = records.value.find(ir => ir.id === item.id);
            if(itemRow) {
                itemRow.folio = item.folio;
                itemRow.purchaseOrderSupplierFolio = item.purchaseOrderSupplierFolio;
                itemRow.supplierName = item.supplierName;
                itemRow.creditDays = item.creditDays;
                itemRow.freightNumber = item.freightNumber;
                itemRow.tax = item.tax;
                itemRow.total = item.total;
            }
        }

        const updateStatusRow = (idRow, status) => {
            let itemRow = records.value.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }
        }

        const handleAditionalActionClick = (action, purchaseOrderId) => {
            switch(action) {
                case "DET":
                    goToDetails(purchaseOrderId);
                    break;
                case "CI":
                    approvalMovement(purchaseOrderId, "Reject", "PurchaseOrders", "Está a punto de reabrir la captura de la orden de compra.");
                    break;
                case "CF":
                    approvalMovement(purchaseOrderId, "EndCapture", "PurchaseOrders", "Está a punto de finalizar la captura de la orden de compra.");
                    break;
                case "AA":
                    approvalMovement(purchaseOrderId, "Approve", "PurchaseOrders");
                    break;
                case "PP":
                    adminPartialPayments(purchaseOrderId)
                    break;
                case "DM":
                    pdfTitle.value = "Orden de Compra";
                    getPurchaseOrderPDF(purchaseOrderId);
                    break;
            }
        }

        const goToDetails = (purchaseOrderId) => { //Permisos
            ApiService.get(`${controller}/${purchaseOrderId}`).then(res => {
                let tmp = records.value.find(r => r.id == purchaseOrderId);
                let showCI = canApplyStatus('CI', purchaseOrderId) && store.getters["canApplyCI"];
                let showCF = canApplyStatus('CF', purchaseOrderId) && store.getters["canApplyCF"];
                let showAA = canApplyStatus('AA',purchaseOrderId) && store.getters["canApplyAA"]
                let tmp2 = `requireCI:${showCI}|requireCF:${showCF}|requireAWM:${showAA}`;
                let dataProps = `purchaseOrderId:${purchaseOrderId}|canEdit:${showCF}|isAdmin:${store.getters["canEditPOAutorized"]}|movementFolio:${res.data.folio}|${tmp2}`;
                if(res && res.status == 200){
                    router.push({
                        name: '/shopping/adminPurchaseOrders/:purchaseOrderId/details',
                        params: {
                            purchaseOrderId: btoa(dataProps),
                            canEdit: false,
                            movementFolio: res.data.folio
                        }
                    });
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getPurchaseOrderPDF = (orderId) => {
            ApiService.post(`movements/ReportMovementPdf?MovementId=${orderId}` ,{})
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        const approvalMovement = (movementeId, approvalType, controllerAp = "purchaseOrders", messageTitle = "Está a punto de autorizar la Orden de Compra.") => {
            Swal.fire({
                title: `${messageTitle} ¿Desea continuar?`,
                icon: "warning",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        purchaseOrderIdProcessing.value = movementeId;
                        ApiService.put(`${controllerAp}/${movementeId}/${approvalType}`).then(res => {
                            purchaseOrderIdProcessing.value = null;
                            if(res.status == 204){
                                refreshSelectedItem(movementeId, updateApprobalStatusCol, approvalType);                          
                            }
                            messageService.success(`El cambio de etapa se realizó exitosamente.`);   
                        }).catch(reject => {
                            purchaseOrderIdProcessing.value = null;
                            getMessageError(reject, messageService, "MessageError");
                        }); 
                    }
                }
            );
        }

        const adminPartialPayments = (poId) => {
            let url = `SettlementPurchaseOrders/GetList/${poId}`;
            ApiService.get(url).then(res => {
                if(res.data.length == 0) {
                    Swal.fire({
                        title: `Aún no se han realizado abonos para esta Orden de Compra. ¿Desea realizar uno?`,
                        icon: "info",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if(btnFormPP.value) {
                                    purchaseOrderIdPP.value = poId;
                                    formPP.value.initDefaults();
                                    btnFormPP.value.click();
                                }
                            } else {
                                let dataProps = `purchaseOrderId:${poId}`;
                                router.push({
                                    name: '/shopping/settlementPurchaseOrders/:C0',
                                    params: {
                                        C0: btoa(dataProps)
                                    }
                                });
                            }
                        }
                    );
                } else {
                    let dataProps = `purchaseOrderId:${poId}`;
                    router.push({
                        name: '/shopping/settlementPurchaseOrders/:C0',
                        params: {
                            C0: btoa(dataProps)
                        }
                    });
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getIsProcessingMovement = (rowId) => {
            return purchaseOrderIdProcessing.value == rowId;
        }

        const refreshSelectedItem = (itemId, callbackFunction, approvalType = "") => {
            ApiService.get(`${controller}/${itemId}`).then(res => {
                if(res && res.status == 200 && callbackFunction){
                    callbackFunction(res.data, approvalType);
                }    
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const updateApprobalStatusCol = (item) => {
            let itemRow = records.value.find(ir => ir.id === item.id);
            if(itemRow) {
                itemRow.status = item.status;
                itemRow.statusName = item.statusName;
            }
        }

        const verifyCanEdit = (movementId) => {
            let movement = records.value.find(r => r.id == movementId);
            return movement ? movement.status == 0 || store.getters["canEditPOAutorized"] : false;
        }

        const checkIfCanAuthorize = (movementId) => {
            let movement = records.value.find(r => r.id == movementId);
            let temp = movement ? movement.status == 1 : false;
            return temp;
        }

        const canDownloadInputMovement = (purchaseOrderId) => {
            let purchaseOrder = records.value.find(r => r.id == purchaseOrderId);
            return purchaseOrder ? purchaseOrder.relatedMovementId != "" && purchaseOrder.whereHouseManagerApproval : false;
        }

        const clearFilters = async() => {
            textAdvancedSearch.value = "";
            filterSupplier.value = "";
            filterFolio.value = "";
            filterStatus.value = null;
            filterStartDate.value = null;
            filterEndDate.value = "";
            await getPagedItems();
        }

        let tmpSD = ref("");
        let tmpFD = ref("");
        const applyFilters = async() => {
            textAdvancedSearch.value = "";
            addAdvancedFilter(filterSupplier, "SupplierIds");
            addAdvancedFilter(filterFolio, "Folio");
            addAdvancedFilter(filterStatus, "PurchaseOrdersStatus");

            if(filterStartDate.value) {
                tmpSD.value = filterStartDate.value.toISOString()
                addAdvancedFilter(tmpSD, "InitialDate");
            }

            if(filterEndDate.value) {
                tmpFD.value = filterEndDate.value.toISOString();
                addAdvancedFilter(tmpFD, "FinalDate");
            }
            await getPagedItems();
        }

        const addAdvancedFilter = (container, name) => {
            textAdvancedSearch.value = container.value.trim() != "" ? `${textAdvancedSearch.value}&Criteria.${name}=${container.value.trim()}` : textAdvancedSearch.value.trim();
        }

        const onChangeSupplier = (id) => {
            filterSupplier.value = id.trim();
        }

        const onChangeFolio = (folio) => {
            filterFolio.value = folio.trim();
        }

        const searchSuppliers = async(filter) => {
            if(filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const getComboOptions = async(resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const canApplyStatus = (status, rowId) => {
            let tmp = records.value.find(r => r.id == rowId);
            let can = false;
            if(tmp) {
                switch(status){
                    case "CI":
                        can = tmp.status == 1;
                        break;
                    case "CF":
                        can = tmp.status == 0;
                        break;
                    case "AA":
                        can = tmp.status == 1;
                        break;
                    case "DM":
                        can = tmp.status > 0;
                        break;
                }
            }

            return can;
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            form,
            btnFormPP,
            formPP,
            idCollapse,

            //Filtros
            filterSupplier,
            filterFolio,
            filterStatus,
            filterStartDate,
            filterEndDate,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentPO,
            modeEditForm,
            openPDF,
            pdfMovement,
            getPurchaseOrderIdPP,
            comboSupplier,
            comboStatus,
            getCanApplyCI,
            getCanApplyCF,
            getCanApplyAA,
            getPDFTitle,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleItemSaved,
            searchItems,
            handleAditionalActionClick,
            verifyCanEdit,
            getIsProcessingMovement,
            // canBackToCI,
            checkIfCanAuthorize,
            canDownloadInputMovement,
            clearFilters,
            applyFilters,
            onChangeFolio,
            searchSuppliers,
            onChangeSupplier,
            canApplyStatus
        }
        
    },
})
</script>

<i18n>
{
    "es": {
        "LABELS": {
            "ADVANCE_SERACH": "Búsqueda Avanzada"
        }
    }
}
</i18n>
